import React, {useEffect, useState} from "react"
import Widget from "../../components/widget";
import {Form} from "react-final-form";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {Button} from "../../components/My/Button";
import RulesModal from "./RulesModal";
import FieldArrayRules from "./FieldArrayRules";
import {apiCreateTurniketRule, apiGetRuleById, apiUpdateTurniketRule} from "./Actions";
import SubjectField from "../../FinalForm/api/SubjectField";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "cogo-toast";
import InputField from "../../FinalForm/InputField";
import {parseISO} from "date-fns";
import SelectField from "../../FinalForm/SelectField";
import {EnigooLoader} from "../../components/My/EnigooLoader";
import CheckboxField from "../../FinalForm/CheckboxField";
import DateTimeField from "../../FinalForm/DateTimeField";

const RulesForm = () => {
    let [item, setItem] = useState(null)
    let [modalRule, setModalRule] = useState(false)
    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    const {id} = useParams()
    let navigate = useNavigate()

    let ticketTypes = [
        {label: "Vstupenky", value: 1},
        {label: "Permanentky", value: 2},
        {label: "Vstupenky partnerský program", value: 5},
        {label: "Produkty", value: 9},
        {label: "Časové sloty", value: 18},
    ];

    useEffect(() => {
        id && getRule()
    }, [])

    const getRule = () => {
        setLoading(true)
        apiGetRuleById((id), (data) => {
            let parsedData = {...data[0]};
            parsedData.rules = parsedData.rules.map(rule => {
                let newRule = {...rule};
                if (newRule.dateFrom) newRule.dateFrom = newRule.dateFrom;
                if (newRule.dateTo) newRule.dateTo = newRule.dateTo;
                return newRule;
            })
            setData(parsedData)
            setLoading(false)
        }, (err) => {
            switch (err.error.type) {
                case "RULE_NOT_EXIST":
                    Toast.error("  Pravidlo turniketu neexistuje.")
                    break;
                default:
                    Toast.error(" Neočekávaná chyba.")
            }
            setLoading(false)
        })
    }

    const onSubmit = values => {
        setLoading(true)

        if (id) {
            apiUpdateTurniketRule((values), (data) => {
                setLoading(false)
                Toast.success("Dokončeno")
                navigate("/rules")
            }, (err) => {
                switch (err.error.type) {
                    case "TURNIKET_RULE_NOT_EXIST":
                        Toast.error(" Pravidlo turniketů neexistuje.")
                        break;
                    case "SUBJECT_NOT_EXIST":
                        Toast.error(" Subjekt nebyl nalezen.")
                        break;
                    case "SCHEMA_NOT_VALID":
                        Toast.error(" Schéma není validní.")
                        break;
                    case "SUBJECT_ALREADY_IN_RULES":
                        Toast.error(" Objekt už v pravidlech existuje.")
                        break;
                    case "INVALID_DATE_FORMAT":
                        Toast.error(" Špatný formát datumu.")
                        break;
                    default:
                        Toast.error(" Nastala neočekávaná chyba.")
                }
                setLoading(false)
            })
        } else {
            apiCreateTurniketRule((values), (data) => {
                setLoading(false)
                Toast.success("Dokončeno")
                navigate("/rules")
            }, (err) => {
                switch (err.error.type) {
                    case "SUBJECT_NOT_EXIST":
                        Toast.error(" Subjekt nebyl nalezen.")
                        break;
                    case "SCHEMA_NOT_VALID":
                        Toast.error(" Schéma není validní.")
                        break;
                    case "SUBJECT_ALREADY_IN_RULES":
                        Toast.error(" Objekt už v pravidlech existuje.")
                        break;
                    case "INVALID_DATE_FORMAT":
                        Toast.error(" Špatný formát datumu.")
                        break;
                    default:
                        Toast.error(" Nastala neočekávaná chyba.")
                }
                setLoading(false)
            })
        }

    }


    const validate = (values) => {
        const errors = {};
        const rules = values.rules || [];

        if (!values.subjectId) {
            errors.subjectId = true
        }
        if (!values.subjectType) {
            errors.subjectType = true
        }

        const findDuplicates = (array) => {
            let counts = {};
            for (let i = 0; i < array.length; i++) {
                let element = array[i];
                if (element !== undefined) {
                    if (counts[element] === undefined) {
                        counts[element] = 1;
                    } else {
                        counts[element]++;
                    }
                }
            }

            let duplicates = [];

            for (let element in counts) {
                if (counts[element] > 1) {
                    duplicates.push(element);
                }
            }

            return duplicates;

        }

        if (rules?.length > 0) {

            rules.forEach((rule, index) => {
                if (rule?.setStaticExpire) {

                    if(!rule?.dateFrom || !rule?.dateTo){
                        errors.date = "Zadejte datum OD A DO!"
                    }

                    if (rule?.dateFrom && rule?.dateTo && (rule?.dateFrom > rule?.dateTo)) {
                        errors.date = "Datum DO musí být déle než datum OD."
                    }

                }

                if (rule?.setDynamicExpire) {

                    if(!rule?.modify) {
                        errors.date = "Vyberte možnost posunout datum platnosti u pravidla."
                    }
                    if(rule?.modify === 'BY_SLOT'){

                        if(!rule?.timeBefore) {
                            errors.date = "Zadejte minuty platnosti před startem časového slotu."
                        }
                        if(!rule?.timeAfter && !rule?.timeByDuration){
                            errors.date = "Vyberte hodnotu konce expirace u časového slotu. Čas po začátku nebo dle délky čas. slotu."
                        }
                    }

                }


                if(!rule?.setStaticExpire && !rule?.setDynamicExpire){
                    errors.date = "Vyberte jednu z možností platnosti vstupenek."
                }

                if (!rule?.count) {
                    errors.count = "Chybí záznam o počtu.";
                }

                if (!rule?.serviceId) {
                    errors.serviceId = "Chybí záznam o externím id.";
                }

                if (!rule?.category && !rule?.discount && !rule?.product) {
                    errors.categoryDiscountError = "V každém řádku musí být alespoň jedna kategorie nebo sleva.";
                }

            })

            let allCategories = rules.flatMap(rule => rule?.category?.map(cat => cat?.id));
            let allDiscounts = rules.flatMap(rule => rule?.discount?.map(dis => dis?.id));
            let allProducts = rules.flatMap(rule => rule?.product?.map(dis => dis?.id));
            if (allCategories[0] !== undefined || allDiscounts[0] !== undefined || allProducts[0] !== undefined) {

                let dc = findDuplicates(allCategories);
                let ds = findDuplicates(allDiscounts);
                let ps = findDuplicates(allProducts);

                if (dc?.length > 0) {
                    errors.categoryError = "Duplikátní kategorie: " + dc.toString()
                }
                if (ds?.length > 0) {
                    errors.discountError = "Duplikátní slevy: " + ds.toString()
                }
                if (ps?.length > 0) {
                    errors.discountError = "Duplikátní produkt ID: " + ps.toString()
                }
            } else {
                errors.discountError = "Přidejte aspoň jednu kategorii nebo slevu."
            }
        } else {
            errors.rulesError = true
        }


        return errors
    }

    if (loading) return <EnigooLoader/>

    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black"}>
                    <h5 className={"align-middle"}>{data ? "Upravit pravidlo" : "Vytvořit pravidlo"}</h5>
                    <span className="ml-auto"></span>
                </div>
            </Widget>

            <Form onSubmit={onSubmit} mutators={{
                ...arrayMutators
            }}
                  validate={validate}
                  initialValues={data ? data : {}}
                  render={({handleSubmit, form, values, errors}) => {
                      return (
                          <>
                              <Widget>
                                  <div className={"w-full flex"}>
                                      <div className={"w-1/3"}><SubjectField name={"subjectId"}
                                                                             label={"Vyberte subjekt"}/></div>
                                      <div className={"w-1/3 pl-2"}><SelectField name={"subjectType"}
                                                                                 label={"Vyberte typ vstupného"}
                                                                                 disabled={!!data}
                                                                                 options={ticketTypes}
                                                                                 isMandatory={true}
                                      /></div>
                                  </div>
                                  <Button text={"Přidat řádek pravidla"}
                                          onClick={() => form.mutators.push('rules', undefined)}/>
                              </Widget>
                              <FieldArray name="rules">
                                  {({fields}) =>
                                      fields.map((name, index) => (
                                          <Widget key={name}>
                                              <div key={"rule_row" + index} className={"flex flex-row"}>
                                                  <h5>Pravidla řádek #{index + 1}</h5>
                                                  <span className="ml-auto"></span>
                                                  <Button text={"Odstranit řádek"}
                                                          color={"bg-red-500"} textSize={"sm"}
                                                          type={"button"}
                                                          onClick={() => {
                                                              setModalRule(true)
                                                              setItem({fields, index})
                                                          }}/>
                                              </div>
                                              <div className={"flex flex-wrap mt-2 w-full "}>
                                                  <div className={"w-1/2"}>
                                                      <InputField label={"Externí ID"} name={`${name}.serviceId`}
                                                                  isMandatory={true}
                                                                  inputType={"number"}/>
                                                  </div>
                                                  <div className={"w-1/2"}>
                                                      <InputField label={"Počet"} name={`${name}.count`}
                                                                  isMandatory={true}
                                                                  min={0}
                                                                  inputType={"number"}/>
                                                  </div>
                                              </div>
                                              <div
                                                  className={`flex flex-wrap mt-2 w-full ${fields?.value?.[index]?.setStaticExpire ? "bg-green-100" : ""}`}>
                                                  <div className={"w-1/3"}>
                                                      <CheckboxField label={"Nastavit pevnou platnost"}
                                                                     name={`${name}.setStaticExpire`}
                                                                     isMandatory={false}
                                                                     disabled={!!fields?.value?.[index]?.setDynamicExpire}/>
                                                  </div>
                                                  <div className={"w-1/3"}>
                                                      <DateTimeField name={`${name}.dateFrom`}
                                                                     label={"Datum platnosti od"}
                                                                     isMandatory={!!fields?.value?.[index]?.setStaticExpire}
                                                                     disabled={!!fields?.value?.[index]?.setDynamicExpire}
                                                                     type={"from"}/>
                                                  </div>
                                                  <div className={"w-1/3"}>
                                                      <DateTimeField name={`${name}.dateTo`}
                                                                     label={"Datum platnosti do"}
                                                                     isMandatory={!!fields?.value?.[index]?.setStaticExpire}
                                                                     disabled={!!fields?.value?.[index]?.setDynamicExpire}
                                                                     type={"to"}/>
                                                  </div>
                                              </div>
                                              <div
                                                  className={`flex flex-wrap mt-2 w-full ${fields?.value?.[index]?.setDynamicExpire ? "bg-green-100" : ""}`}>
                                                  <div className={"w-1/4"}>
                                                      <CheckboxField label={"Nastavit dynamickou platnost vstupenky"}
                                                                     name={`${name}.setDynamicExpire`}
                                                                     isMandatory={false}
                                                                     disabled={!!fields?.value?.[index]?.setStaticExpire}/>
                                                  </div>
                                                  <div className={"w-1/4"}>
                                                      <SelectField name={`${name}.modify`} options={[
                                                          {label: "+ 1 rok", value: "+1 year"},
                                                          {label: "+ 2 roky", value: "+2 year"},
                                                          {label: "+ 1 den", value: "+1 day"},
                                                          {label: "+ 7 dní", value: "+7 day"},
                                                          {label: "Dle časového slotu", value: "BY_SLOT"}
                                                      ]} label={"Posunout platnost"}
                                                                   disabled={!!fields?.value?.[index]?.setStaticExpire}
                                                                   isMandatory={!!fields?.value?.[index]?.setDynamicExpire}
                                                      />
                                                  </div>
                                                  {fields?.value?.[index]?.modify === 'BY_SLOT' &&
                                                      <>
                                                          <div className={"w-1/6"}>
                                                              <InputField label={"Čas(m) před začátkem slotu"}
                                                                          name={`${name}.timeBefore`} min={0}
                                                                          disabled={!!fields?.value?.[index]?.setStaticExpire}
                                                                          isMandatory={false} inputType={"number"}
                                                              />
                                                          </div>
                                                          <div className={"w-1/6"}>
                                                              <InputField label={"Čas(m) po začátku slotu"}
                                                                          inputType={"number"} min={0}
                                                                          name={`${name}.timeAfter`}
                                                                          isMandatory={false}
                                                                          disabled={!!fields?.value?.[index]?.timeByDuration || !!fields?.value?.[index]?.setStaticExpire}/>
                                                          </div>
                                                          <div className={"w-1/6"}>
                                                              <CheckboxField label={"Konec platnosti dle délky trvání"}
                                                                             name={`${name}.timeByDuration`}
                                                                             isMandatory={false}
                                                                             disabled={!!fields?.value?.[index]?.timeAfter || !!fields?.value?.[index]?.setStaticExpire}/>
                                                          </div>
                                                      </>}
                                              </div>
                                              <FieldArrayRules name={`${name}.category`} label={"Kategorie"}
                                                               buttonText={"Přidat ID kategorií"}
                                                               push={form.mutators.push}/>
                                              <FieldArrayRules name={`${name}.discount`} label={"Slevy"}
                                                               buttonText={"Přidat ID slev"} push={form.mutators.push}/>
                                              <FieldArrayRules name={`${name}.product`} label={"Produkty"}
                                                               buttonText={"Přidat ID produktů/okruhů"}
                                                               push={form.mutators.push}/>
                                          </Widget>
                                      ))
                                  }
                              </FieldArray>
                              {Object.keys(errors).length !== 0 &&
                                  <Widget>
                                      {errors?.subjectId && <h6 className={"text-red-400"}>Vyberte subjekt.</h6>}
                                      {errors?.subjectType && <h6 className={"text-red-400"}>Vyberte typ subjektu.</h6>}
                                      {errors?.rulesError &&
                                          <h6 className={"text-red-400"}>Vytvořte alespoň jedno pravidlo.</h6>}
                                      {errors?.categoryDiscountError &&
                                          <h6 className={"text-red-400"}>{errors?.categoryDiscountError}</h6>}
                                      {errors?.categoryError &&
                                          <h6 className={"text-red-400"}>{errors?.categoryError}</h6>}
                                      {errors?.discountError &&
                                          <h6 className={"text-red-400"}>{errors?.discountError}</h6>}
                                      {errors?.count &&
                                          <h6 className={"text-red-400"}>{errors?.count}</h6>}
                                      {errors?.serviceId &&
                                          <h6 className={"text-red-400"}>{errors?.serviceId}</h6>}
                                      {errors?.date && <h6 className={"text-red-400"}>{errors?.date}</h6>}
                                      {errors?.rules &&
                                          <h6 className={"text-red-400"}>Vyplňte všechny povinné parametry.</h6>}
                                  </Widget>
                              }
                              <Button text={"Uložit"} onClick={handleSubmit} loading={loading}/>
                          </>
                      )
                  }}
            />

            <RulesModal
                visible={modalRule}
                accept={() => {
                    item.fields.remove(item.index)
                    setModalRule(false)
                    setItem(null)
                }}
                close={() => {
                    setItem(null)
                    setModalRule(false)
                }}/>

        </>
    )
}
export default RulesForm
