import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import Select from "react-select";


const SelectField = (props) => {


    const getParse = (value) => {
        if (value === null) return null;
        if (props.isMulti) {
            return value.map(item => item.value)
        } else {
            return value.value;
        }
    };

    const getValue = (value) => {

        if (!props.isMulti) {
            let item = props.options.find(item => item.value === value)
            if (!item) {
                let final = null;
                props.options.map(option => {
                    if (option.options) {
                        let _item = option.options.find((itemValue) => itemValue.value === value)
                        if (!_item) return null;
                        final = {value: _item.value, label: _item.name}
                    }
                })
                return final;
            }
            return item;
        } else {
            let array = [];
            if (value instanceof Array) {
                value.map(item => {
                    props.options.map(option => {
                        if (option.options) {
                            let _item = option.options.find((itemValue) => itemValue.value === item)
                            if (!_item) return null;
                            array.push({
                                value: _item.value, label: item.name
                            })
                        } else if (item === option.value) {
                            array.push({value: option.value, label: option.label})
                        }
                    })
                })
            }


            return array
        }
    };

    const errorMessage = (message) => {
        return (
            <span style={{color: "red", marginRight: "10px"}} className={"text-sm"}>
                <i className="fa fa-arrow-up"> </i> {message}
            </span>
        );
    };

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole");
        }
        return null;
    };

    return (
        <Field
            {...props}
            name={props.name}

            validate={(value) => {
                if (props.isMandatory) {
                    if (!value && value !==0) return true;
                }
                return false;
            }}
            render={(_props) => (
                <div className={"w-full p-1"}>
                    <div className={"form-label font-bold pb-1 pt-1"}>
                        {props.label && props.label + ": "}
                        {props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}
                    </div>
                    <Select
                        {...props}
                        styles={{
                            menu: (base) => ({...base, zIndex: 9999}),
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                        }}
                        isMulti={props.isMulti}
                        placeholder={props.placeholder ? props.placeholder : "Vyberte..."}
                        onChange={(value) => {
                            _props.input.onChange(value)
                            if (props.onChange) {
                                props.onChange(value);
                            }
                        }}
                        options={props.options}
                        value={_props.input.value}
                        isDisabled={props.disabled}
                        isClearable
                    />
                    {renderError(_props)}
                </div>
            )}
            parse={getParse}
            format={getValue}
        />
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    isMandatory: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.object,
    dataTest: PropTypes.string
};
SelectField.defaultProps = {
    isMandatory: false,
    isMulti: false,
};

export default SelectField;
