import React from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import {EnigooLoader} from "../../components/My/EnigooLoader";

const TableData = ({data, loading, prepareItem}) => {

   const types = {
      1: "Vstupenky",
      2: "Permanentky",
      5: "Vstupenky partnerský program",
      9: "Produkty",
       18: "Časové sloty"
   }

   if (loading) return <EnigooLoader />
   if (!data) return null

   let columns = [
         {Header: 'ID', accessor: "id"},
         {Header: 'Subjekt', accessor: "subjectName"},
         {
            Header: 'Typ', accessor: d => (
               types[d.subjectType]
            )
         },
         {
            Header: 'Možnosti', accessor: d => (
               <Button text={"Upravit"} link={"/rules/" + d.id} />
            )
         },
         {
            Header: 'Odstranění', accessor: d => (
               <Button text={"Odstranit"} color={"bg-red-500"} onClick={() => prepareItem(d)} />
            )
         }
      ]
   ;


   return (
      <Widget >
         <Datatable data={data} columns={columns} />
      </Widget >
   )
}

export default TableData
