import React, {useEffect, useState} from "react";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {apiGetTariff} from "./Actions";
import TariffTable from "./TariffTable";
import {Button} from "../../../components/My/Button";
import UserArealSelect from "../Turnstiles/UserArealSelect";
import {useSelector} from "react-redux";
import Header from "../../../components/My/Header";

const Tariff = () => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData()
    }, [user.activeAreal]);

    const getData = () => {
        setLoading(true);
        apiGetTariff(user.activeAreal.id, null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            Toast.error("Při načítání tarifů nastala chyba.");
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám tarify..."}/>;
        if (!loading && data) return <TariffTable data={data} setData={setData}/>
        else return <>Žádná data</>
    }

    return (
        <>
            <UserArealSelect/>
            {user.activeAreal &&
                <Header data={[ {text: "Tarify", isMain: true}]}
                        rightContent={ <Button text={"Přidat tarif"} link={"/tariff/add"} loading={loading}/>
                        }>
                    {_renderBody()}
                </Header>
            }
        </>
    )
}
export default Tariff;
