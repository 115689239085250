import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const DateTimeField = (props) => {

    const renderError = (meta, value) => {

        if (meta.error && meta.touched) {
            if(props.validateFun && props.valueToCompare){
                let error= props.validateFun(value,props.valueToCompare);
                if(error==="") return null;
                else{
                    return <div className={"text-red-500"}>{error}</div>;
                }
            }
            if (!value || value === "") {
                return <div className={"text-red-500"}>Povinné pole</div>;
            }
        }
        return null;
    };

    const _renderClassName = (meta, value) => {
        if (props.disabled) return 'form-input text-sm text-gray-400 bg-grey-200';
        let className = `form-input text-sm text-gray-400`;
        if (meta.touched && meta.error) {
            className += "border-red-500 ";
        } else if (value && props.minLength && value.length < props.minLength) {
            className += "border-red-500 ";
        } else if (value && props.maxLength && value.length > props.maxLength) {
            className += "border-red-500 ";
        } else if (props.checkWithValue && value !== props.checkWithValue) {
            className += "border-red-500 ";
        } else if (value) {
            className += "border-green-500 ";
        }

        return className;
    };

    const editValue = (val) => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        let actualTime = `T${hours}:${minutes}`;

        let value = null;
        if (!val) return value;
        else if (props.type && props.type === "from" && val.slice(val.indexOf("T"), val.length) === actualTime) {
            return val.slice(0, val.indexOf("T")) + "T00:00";
        } else if (props.type && props.type === "to" && val.slice(val.indexOf("T"), val.length) === actualTime) {
            return val.slice(0, val.indexOf("T")) + "T23:59";
        } else {
            return val;
        }
    }

    return (
        <Field
            name={props.name}
            validate={(value) => {
                if(props.validateFun && props.valueToCompare){
                    if(props.validateFun(value,props.valueToCompare)===""){

                    }else{
                        return true;
                    }
                }
                if (props.isMandatory) {
                    if (!value) return true;
                }

            }}
            render={(_props) => {
                return (
                    <div className={"w-full flex flex-col text-sm p-1"}>
                        {props.label && (
                            <div className="form-label font-bold flex flex-col pt-1 pb-1">
                                <span>{props.label + ": "}{props.minLength ?
                                    <span className={"text-sm"}>(minimálně {props.minLength} znaků)</span> : null}
                                    {props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}</span>
                                {props.note && <span className={"text-sm font-normal"}>{props.note}</span>}
                            </div>

                        )}
                        <input placeholder={props.placeholder ? props.placeholder : "Zadejte"}
                               type={props.onlyTime ? "time" : props.showTimeSelect ? "datetime-local" : "date"}
                               className={_renderClassName(_props.meta, _props.input.value)}
                               disabled={props.disabled}
                               maxLength={props.maxLength ? props.maxLength : 20000}
                               value={_props.input.value}
                               onChange={(e) => {
                                   if (props.onChange) {
                                       props.onChange(props.name, e.target.value);
                                   }
                                   if (!_props.input.value) {
                                       e.target.value = editValue(e.target.value);
                                   }
                                   _props.input.onChange(e.target.value);
                               }}
                               id={props.id}
                               onKeyDown={({key}) => {
                                   if (key === "Enter") {
                                       if (props.onEnter) props.onEnter();
                                   }
                               }}
                        />

                        <div className={"text-sm pt-1"}> {renderError(_props.meta, _props.input.value)} </div>
                    </div>
                );
            }}
        />
    );
};
DateTimeField.defaultProps = {
    inputType: "from",
    disabled: false,
    showTimeSelect:false,
    onlyTime:false
};

DateTimeField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    note: PropTypes.string,
    type: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    onEnter: PropTypes.func,
    onlyTime:PropTypes.bool
};

export default DateTimeField;
