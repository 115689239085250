import {Button} from "../../../components/My/Button";
import TurnstileTable from "./TurnstileTable";
import React, {useEffect, useState} from "react";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {IconTabs} from "../../../components/tabs";
import {apiGetTurnstile} from "./Actions";
import {AiOutlineCheckCircle} from "react-icons/ai";
import UserArealSelect from "./UserArealSelect";
import Toast from "cogo-toast";
import Header from "../../../components/My/Header";

const Turnstile = () => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let user = useSelector((state) => state.user)


    useEffect(() => {
        user.activeAreal && getData(user.activeAreal.id)
    }, [user.activeAreal]);

    const getData = (arealId) => {
        setLoading(true);
        apiGetTurnstile(arealId, null,(data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            Toast.error("Při načítání turniketů nastala chyba.");
            setData(null)
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám turnikety..."}/>;
        if (data) return <IconTabs tabs={[
            {
                index: 0,
                title: (<><AiOutlineCheckCircle size={18} className={"stroke-current"}/><span
                    className={"ml-2"}>Nastavené</span> </>),
                content: <TurnstileTable data={data} setData={setData} arealId={user.activeAreal.id}/>
            }
        ]}/>
        else return <>Žádná data</>
    }

    return (
        <>
            <UserArealSelect/>
            {user.activeAreal &&
                <Header data={[ {text: "Turnikety", isMain: true}]}
                    rightContent={ <Button text={"Přidat turniket"} link={"/turnstile/add"} loading={loading}/>
                }>
                    {_renderBody()}
                </Header>
            }

        </>
    )
}
export default Turnstile
